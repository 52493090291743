import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import moment from 'moment-timezone';
import { object } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Create, SimpleForm, useCreate, useNotify, Toolbar, useDataProvider } from 'react-admin';
import getDefaultLanguage from 'utils/getDefaultLanguage';
import CreateList from '../../components/EventCreate/CreateList';
import RecurringEventForm from '../../components/EventCreate/RecurringEventForm';
import transformDatesToCorrectUTCdate from './helpers/transformDatesToCorrectUTCdate';
import createRuleSet from './helpers/createRuleSet';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    display: 'flex',
  },
  leftCol: {
    marginRight: theme.spacing(1),
    minWidth: 400,
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  rightCol: {
    minWidth: 250,
  },
  errorText: {
    color: red[500],
  },
}));

const EventCreate = ({ location, ...props }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const initialEvent = {
    date_begin: moment().toISOString(),
    is_published: true,
    company: null,
    duration: 45,
    max_participants: 24,
    event_type_id: null,
    coach_id: null,
    special: false,
    is_chargeable: true,
    flags: {
      needs_coach_replacement: false,
      automatic_cancellations: true,
    },
  };

  const [eventLocation, setEventLocation] = useState(null);
  const [occurrences, setOccurrences] = useState(null);
  const [every, setEvery] = useState(1);
  const [frequency, setFrequency] = useState('weekly');
  const [occurence, setOccurence] = useState(1);
  const [event, setEvent] = useState(location.state?.record || initialEvent);
  const [locationId, setLocationId] = useState(null);
  const [language, setLanguage] = useState('en');
  const [showLanguageWarningMessage, setShowLanguageWarningMessage] = useState(false);
  const [coachLanguage, setCoachLanguage] = useState(null);

  // Update event data when location or language changes
  useEffect(() => {
    handleEventChange();
  }, [locationId, language]);

  useEffect(() => {
    setDefaultLanguage(locationId);
  }, [locationId]);

  useEffect(() => {
    if (coachLanguage) {
      const shouldShow = !coachLanguage.includes(language);
      setShowLanguageWarningMessage(shouldShow);
    }
  }, [coachLanguage, language]);

  // Set default language based on the selected location
  const setDefaultLanguage = (location_id) => {
    if (location_id) {
      dataProvider
        .getOne('locations', { id: location_id })
        .then(({ data: { city_code } }) => {
          const defaultLanguage = getDefaultLanguage(city_code);
          setLanguage(defaultLanguage);
        })
        .catch((e) => {
          // TODO: Write a custom error component
          console.error(e);
        });
    }
  };

  const handleEventChange = (key, value, nestedKey) => {
    if (key === 'coach_id') {
      dataProvider.getOne('trainers', { id: value }).then(({ data }) => {
        setCoachLanguage(data.languages);
      });
    }

    const updatedEvent = { ...event, language, location_id: locationId };

    if (nestedKey) {
      updatedEvent[key][nestedKey] = value;
    } else if (key) {
      updatedEvent[key] = value;
    }

    // Remove special fields if special = false
    if (key === 'special' && value === false) {
      delete updatedEvent.special_event_name;
      delete updatedEvent.special_event_description;
    }

    if (key == 'flags') {
      updatedEvent.flags = {
        ...event.flags,
        [nestedKey]: value,
      };
    }

    setEvent(updatedEvent);
  };

  const calculateOccurrences = () => {
    if (!(isFinite(every) && every > 0 && isFinite(occurence) && occurence > 0)) {
      setOccurrences([]);
      return;
    }

    let occurrences = createRuleSet({ every, occurence, frequency, date: event.date_begin, eventLocation });

    occurrences = transformDatesToCorrectUTCdate(occurrences, timezone);
    setOccurrences(occurrences);
  };

  useEffect(() => {
    calculateOccurrences();
  }, [event, every, frequency, occurence]);

  const [create, { data, error, loading }] = useCreate();
  const notify = useNotify();

  useEffect(() => {
    if (!error && !!data && !loading) {
      notify(`Events created`, 'success');
    }
  }, [data, error, loading]);

  const timezone = _get(eventLocation, 'timezone', 'Europe/Berlin');

  const eventsToCreate = {
    ...event,
    location_id: _get(event, 'location.id', locationId),
    date_begin: occurrences,
  };

  const handleSave = () => {
    create('events', eventsToCreate);
  };

  return (
    <Create {...props}>
      <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />} save={() => handleSave()}>
        <div className={classes.root}>
          <div className={classes.row}>
            <div className={classes.leftCol}>
              <Card className={classes.card}>
                <CardHeader title='Create Event' />
                <CardContent>
                  <RecurringEventForm
                    every={every}
                    frequency={frequency}
                    occurence={occurence}
                    setEvery={setEvery}
                    setFrequency={setFrequency}
                    setOccurence={setOccurence}
                    setLanguage={setLanguage}
                    setLocationId={setLocationId}
                    event={event}
                    handleEventChange={handleEventChange}
                    showLanguageWarningMessage={showLanguageWarningMessage}
                  />
                </CardContent>
              </Card>
            </div>

            <div className={classes.rightCol}>
              <Card className={classes.card}>
                <CardHeader title='Preview & Save Events' />
                <CardContent>
                  <CreateList
                    occurrences={occurrences}
                    event={event}
                    timezone={timezone}
                    isLoading={loading}
                    isSuccess={!!data}
                    isError={!!error}
                  />
                  {error && (
                    <Typography variant='body1' className={classes.errorText} gutterBottom>
                      {error}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

EventCreate.propTypes = {
  location: object.isRequired,
  props: object,
};

export default EventCreate;

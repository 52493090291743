import React from 'react';
import {
  useRefresh,
  NumberField,
  SelectField,
  Tab,
  TextField,
  UrlField,
  Labeled,
  useRecordContext,
  SimpleShowLayout,
  BooleanField,
  FunctionField,
} from 'react-admin';
import axios from 'axios';
import { companies } from 'reactAdmin/users/constants';
import { Button, Grid, Typography } from '@material-ui/core';
import { map, get } from 'lodash';
import AudioSetsReferenceField from 'reactAdmin/audio-sets/AudioSetReferenceField';
import UserReferenceField from 'reactAdmin/users/UserReferenceField';
import moment from 'moment';
import CreateOfferForm from 'reactAdmin/offers/CreateOfferForm';
import { IdField } from 'components/IdField';
import EventTypeReferenceField from '../../event-types/EventTypeReferenceField';
import CustomDateField from '../../fields/CustomDateField';
import TruthyBooleanField from '../../fields/TruthyBooleanField';
import LocationReferenceField from '../../locations/LocationReferenceField';
import TrainerReferenceField from '../../trainers/TrainerReferenceField';
import PartnerSyncStatus from './PartnerSyncStatus';
import RecurringEvent from './RecurringEvent';
import { StatusHistory } from '../StatusHistory';
import { EventCancelled } from './EventCancelled';

const TabOverview = (props) => {
  const refresh = useRefresh();
  const record = useRecordContext(props);
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem('token');
  const eventIsInTheFuture = props.record && moment().isBefore(props.record.date_begin);

  const handleRefresh = () => {
    setTimeout(() => {
      refresh();
    }, 2000);
  };

  const handleGenerateStreamingLink = async () => {
    await axios({
      method: 'POST',
      url: `${apiUrl}/events/${record.id}/generate-streaming-link`,
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    handleRefresh();
  };

  const handlePartnerSync = async () => {
    await axios({
      method: 'POST',
      url: `${apiUrl}/events/${record.id}/propagate-streaming-link`,
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    handleRefresh();
  };

  return (
    <Tab label='Overview' {...props}>
      <Grid container columnSpacing={{ xs: 0 }}>
        <Grid item sm={4}>
          <SimpleShowLayout className='simpleShowLayoutContainer'>
            <IdField id='id' />
            {record?.current_status?.status_name === 'cancelled' && (
              <div className='section-divider'>
                <EventCancelled />
              </div>
            )}
            <BooleanField source='is_published' addLabel />
            <BooleanField source='is_chargeable' addLabel />
            <BooleanField source='flags.automatic_cancellations' label='Automatic Cancellations' addLabel />
            <CustomDateField source='date_begin' timezoneSource='location.timezone' label='Starts At' />
            <CustomDateField
              source='current_status.transitioned_at'
              label={`Status (${get(props, 'record.current_status.status_name')}) transitioned At`}
            />
            <TrainerReferenceField />
            <LocationReferenceField />
            <EventTypeReferenceField />
            <UserReferenceField record={record} source='created_by' label='Created by' />
            <TextField source='language' label='Event Language' />
            {/* Generate streaming links for online events and save to partners */}
            {record?.location?.type === 'online' && (
              <>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Labeled label='Streaming Link'>
                    <UrlField source='streaming_link' emptyText={<span style={{ color: 'red' }}>Link missing</span>} />
                  </Labeled>

                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    style={{ display: 'block' }}
                    onClick={handleGenerateStreamingLink}
                  >
                    {record.streaming_link ? 'REGENERATE LINK' : 'GENERATE LINK'}
                  </Button>
                </div>

                {record.partners_integrations && (
                  <div>
                    <Labeled label='Partner Sync'>
                      <>
                        <Button
                          variant='contained'
                          color='primary'
                          size='medium'
                          style={{ display: 'block', marginBottom: '0.5rem' }}
                          onClick={() => handlePartnerSync(record.id)}
                        >
                          RESEND LINK TO PARTNERS
                        </Button>

                        {map(record.partners_integrations, (status, partner) => (
                          <PartnerSyncStatus partner={partner} status={status} />
                        ))}
                      </>
                    </Labeled>
                  </div>
                )}
              </>
            )}

            <TruthyBooleanField {...props} source='recurrent_id' label='Is Recurrent' />
            <TextField source='current_status.status_name' label='Current status' />

            <SelectField source='company' label='Company' choices={companies} allowEmpty />
            <NumberField source='duration' label='Duration (in minutes)' />
            <CustomDateField source='created_at' />

            {/* Special Fields */}
            <BooleanField source='special' addLabel />
            {record?.special && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Labeled label='Special Event Name'>
                  <TextField source='special_event_name' {...props} />
                </Labeled>
                <Labeled label='Special Event Description'>
                  <TextField source='special_event_description' {...props} />
                </Labeled>
              </div>
            )}

            <IdField source='remote_usc_id' id='remote_usc_id' />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={4}>
          <SimpleShowLayout className='simpleShowLayoutContainer'>
            <Typography variant='title'>Participants</Typography>
            <NumberField source='max_participants' />
            <NumberField source='participants_count' label='Bookings' />
            <NumberField source='attendees_count' label='Attended' />
            <NumberField source='no_shows_count' label='NoShows' />

            <div className='section-divider' />

            <Typography variant='title' style={{ marginTop: '2rem' }}>
              Results
            </Typography>
            <NumberField source='results_count' label='Number of results' />
            <NumberField source='results_with_readings_count' label='Number with results with readings' />

            <div className='section-divider' />

            <Typography variant='title'>Results Stats</Typography>
            <NumberField source='beat_points_sum' label='Sum of Beatpoints' />
            <NumberField source='beat_points_avg' label='Average of Beatpoints' />
            <NumberField source='recovery_points_sum' label='Sum of Recovery Points' />
            <NumberField source='recovery_points_avg' label='Average of Recovery Points' />

            <div className='section-divider' />

            <Typography variant='button'>Playlists</Typography>
            <AudioSetsReferenceField source='music_asset_set_id' label='Music Asset Set' {...props} />
            <AudioSetsReferenceField source='timer_asset_set_id' label='Timer Asset Set' {...props} />
            <FunctionField source='status_history' render={(record) => <StatusHistory record={record} />} />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={4}>
          {eventIsInTheFuture && <CreateOfferForm {...props} />}
        </Grid>
      </Grid>
      <RecurringEvent recurrentId={record.recurrent_id} />
    </Tab>
  );
};

export default TabOverview;
